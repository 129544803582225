import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { connect } from "react-redux";
import firebase from "../../config/fbConfig";
import { signIn } from "../../store/actions/authActions";

class SigninFbUI extends React.Component {
  constructor(props) {
    super(props);
    this.uiConfig = {
      signInFlow: "popup",
      signInSuccessUrl: "/dashboard",
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.GithubAuthProvider.PROVIDER_ID,
        {
          provider: "saml.apiexplorer",
          providerName: "SSO",
          buttonColor: "#007bff",
          iconUrl:
            "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/saml.svg"
        }
      ],
      callbacks: {
        signInSuccessWithAuthResult: function(authUser) {
          props.signIn(authUser);
        }
      }
    };
  }

  render() {
    return (
      <div>
        <StyledFirebaseAuth
          uiConfig={this.uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    firebase: state.firebase
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: authUser => dispatch(signIn(authUser))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SigninFbUI);
