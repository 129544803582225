const initState = {
  authError: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "SIGNIN_ERROR":
      console.log("signin error");
      return {
        ...state,
        authError: action.err.message
      };
    case "SIGNIN_START":
      console.log("signin started");
      return {
        ...state,
        authError: null
      };
    case "SIGNIN_SUCCESS":
      console.log("signin success");
      return {
        ...state,
        authError: null
      };
    case "SIGNIN_PERSISTED":
      console.log("signin persisted");
      return {
        ...state,
        authError: null
      };
    case "SIGNOUT_SUCCESS":
      console.log("signout success");
      return {
        ...state,
        authError: null
      };
    case "PERSIST_SIGNIN_ERROR":
      console.log("persist signin failed");
      return {
        ...state,
        authError: action.err.message
      };
    case "PERSIST_SIGNIN_START":
      console.log("persist signin start");
      return {
        ...state,
        authError: null
      };
    case "PERSIST_SIGNIN_SUCCESS":
      console.log("persist signin success");
      return {
        ...state,
        authError: null
      };
    case "CREATE_SESSION_COOKIE_SUCCESS":
      console.log("create session cookie success");
      return {
        ...state,
        cookieIsSet: true,
        authError: null
      };
    case "CREATE_SESSION_COOKIE_ERROR":
      console.log("create session cookie error");
      return {
        ...state,
        cookieIsSet: false,
        authError: action.err.message
      };
    default:
      return state;
  }
};

export default authReducer;
