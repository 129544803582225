import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import apiexplorer_logo from "../../assets/images/apiexplorer_logo.png";
import SigninFbUI from "./SigninFbUI";

function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Built with love by the "}
      <Link
        color="inherit"
        href="https://medium.com/palo-alto-networks-developer-blog/introducing-developer-relations-at-palo-alto-networks-c9b4a8817c4"
      >
        Trash Panda
      </Link>
      {" team."}
    </Typography>
  );
}

// Loading message
function getSidePhoto() {
  var photos = [
    "url(https://source.unsplash.com/RMePDGG3SU4/900x1600)",
    "url(https://source.unsplash.com/xIziStlwEsU/900x1600)",
    "url(https://source.unsplash.com/xr-y6Ruw7K8/900x1600)",
    "url(https://source.unsplash.com/ieic5Tq8YMk/900x1600)",
    "url(https://source.unsplash.com/U0tBTn8UR8I/900x1600)"
  ];
  var photo = photos[Math.floor(Math.random() * photos.length)];
  return photo;
}

const styles = theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: getSidePhoto(),
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3, 8)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.white,
    borderRadius: 0
  }
});

class SignInSide extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar src={apiexplorer_logo} className={classes.avatar} />
            <Typography component="h1" variant="h5" />
            <Divider variant="fullWidth" absolute />
            <SigninFbUI />
            <Box mt={5}>
              <MadeWithLove />
            </Box>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(SignInSide);
