import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper
} from "@material-ui/core";
import { Checkbox, Select, TextField } from "final-form-material-ui";
import React from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { connect } from "react-redux";
import submitQuery from "../../store/actions/explorerActions";
import FormStateToRedux from "./redux/FormStateToRedux";

const useStyles = makeStyles(theme => ({
  root: {
    width: "auto",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0)
  },
  table: {
    minWidth: 650
  },
  container: {
    display: "flex",
    padding: theme.spacing(0),
    alignItems: "stretch",
    overflowX: "auto",
    overflowY: "auto"
  },
  button: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  formControl: {
    width: 595,
    padding: theme.spacing(1),
    textAlign: "left"
  },
  item: {
    padding: theme.spacing(1)
  }
}));

const validate = values => {
  const validQuery = new RegExp("^(SELECT|WITH)\\s.+FROM\\s.+", "i");
  const errors = {};
  if (values.query && !values.query.match(validQuery)) {
    errors.query = "Invalid query syntax";
  }
  return errors;
};

const WhenFieldChanges = ({ field, becomes, set, to }) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange } }
    ) => (
      <OnChange name={field}>
        {value => {
          if (value === becomes) {
            onChange(to);
          }
        }}
      </OnChange>
    )}
  </Field>
);

function QueryForm(props) {
  const classes = useStyles();

  return (
    <div>
      <Form
        onSubmit={props.submitQuery}
        initialValues={{
          valuesArray: false,
          instanceId: props.selectedInstance,
          accountId: props.selectedAccount
        }}
        validate={validate}
        render={({
          handleSubmit,
          reset,
          submitting,
          pristine,
          dirty,
          values,
          form
        }) => (
          <form
            className={classes.container}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <FormStateToRedux form="queryForm" />
            <WhenFieldChanges
              field="selectedQuery"
              becomes={values.selectedQuery}
              set="query"
              to={values.selectedQuery}
            />
            <Paper className={classes.root} elevation={2} justify="left">
              <Grid className={classes.container} container spacing={1}>
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="query"
                      component={TextField}
                      multiline
                      type="text"
                      placeholder="SELECT * FROM `2020001.firewall.traffic` LIMIT 100"
                      disabled={submitting || props.queryInProgress}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item className={classes.item}>
                  <FormControl className={classes.formControl}>
                    <Field
                      name="selectedQuery"
                      component={Select}
                      label="Sample Query Library"
                      autoWidth
                      native
                      disabled={submitting || props.queryInProgress}
                    >
                      <optgroup label="Traffic">
                        <option value="" disabled></option>
                        <option value="SELECT * FROM `2020001.firewall.traffic` LIMIT 100">
                          TRAFFIC | Last 100 Traffic Events
                        </option>
                        <option value="SELECT DISTINCT source_ip.value AS IP FROM `2020001.firewall.traffic` LIMIT 100">
                          TRAFFIC | Distinct Source IPs
                        </option>
                        <option value="SELECT DISTINCT dest_ip.value AS IP FROM `2020001.firewall.traffic` LIMIT 100">
                          TRAFFIC | Distinct Destination IPs
                        </option>
                        <option value="SELECT SUM(bytes_sent) AS total_bytes_sent FROM `2020001.firewall.traffic`">
                          TRAFFIC | Total Bytes Sent
                        </option>
                        <option value="SELECT source_ip.value AS ip_addr, SUM(bytes_total) AS total FROM `2020001.firewall.traffic` GROUP BY ip_addr ORDER BY Total DESC">
                          TRAFFIC | Total Bytes Transferred by Source IP
                        </option>
                        <option value="SELECT app_category, COUNT(session_id) AS num_sessions FROM `2020001.firewall.traffic` GROUP BY app_category ORDER BY num_sessions DESC">
                          TRAFFIC | Total Sessions by App Category
                        </option>
                        <option value="SELECT session_id, source_ip.value AS ip, bytes_total FROM `2020001.firewall.traffic` WHERE bytes_total > (5 * 1024 * 1024)">
                          TRAFFIC | Greater than 5MB Transferred
                        </option>
                      </optgroup>
                      <optgroup label="Threat">
                        <option value="SELECT * FROM `2020001.firewall.threat` LIMIT 100">
                          THREAT | Last 100 Threat Events
                        </option>
                      </optgroup>
                      <optgroup label="Joins">
                        <option value="SELECT traffic.session_id, traffic.source_ip.value, bytes_total, threat_category FROM `2020001.firewall.traffic` AS traffic JOIN `2020001.firewall.threat` AS threat ON traffic.session_id = threat.session_id WHERE traffic.bytes_total > (5 * 1024 * 1024) LIMIT 10">
                          JOIN | Join Traffic and Threat
                        </option>
                        <option value="SELECT FORMAT_TIMESTAMP('%F %T', traffic.session_start_time) AS session_start_time, traffic.session_id, traffic.source_ip.value, bytes_total, threat_category FROM `2020001.firewall.traffic` AS traffic JOIN `2020001.firewall.threat` AS threat ON traffic.session_id = threat.session_id AND traffic.log_source_id = threat.log_source_id WHERE traffic.bytes_total > (1 * 1024 * 1024) AND TIMESTAMP_TRUNC(traffic.session_start_time, HOUR) = PARSE_TIMESTAMP('%F %T', '2019-08-28 01:00:00')">
                          JOIN | Join Traffic and Threat Advanced
                        </option>
                      </optgroup>
                    </Field>
                  </FormControl>
                </Grid>
                <Grid container item xs={12}>
                  <FormControlLabel
                    label="valuesArray"
                    className={classes.formControl}
                    control={
                      <Field
                        name="valuesArray"
                        component={Checkbox}
                        type="checkbox"
                        disabled={submitting || props.queryInProgress}
                      />
                    }
                  />
                </Grid>
                <Grid item className={classes.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={submitting || props.queryInProgress}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </form>
        )}
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    queryInProgress: state.explorer.queryInProgress,
    firebase: state.firebase,
    selectedAccount: state.firebase.profile.selectedAccount,
    selectedInstance: state.firebase.profile.selectedInstance
  };
};

const mapDispatchToProps = dispatch => ({
  submitQuery: q => dispatch(submitQuery(q))
});

export default connect(mapStateToProps, mapDispatchToProps)(QueryForm);
