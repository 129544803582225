import { firebaseReducer } from "react-redux-firebase";
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import authReducer from "./authReducer";
import authzReducer from "./authzReducer";
import explorerReducer from "./explorerReducer";
import formReducer from "./formReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  explorer: explorerReducer,
  authz: authzReducer
});

export default rootReducer;
