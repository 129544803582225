import firebase from "../../config/fbConfig";
import ApiClient from "../../utils/api";

const perf = firebase.performance();

export function submitQuery(q) {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const trace = perf.trace("queryTrace");
    const firebase = getFirebase();
    const firestore = getFirestore();
    const idToken = getState().firebase.profile.idToken;
    const client = new ApiClient(
      idToken,
      firebase.auth().currentUser,
      dispatch,
      firestore
    );
    const { query, instanceId, accountId, valuesArray } = q;
    var resultFormat = null;
    if (valuesArray) {
      resultFormat = "valuesArray";
    }
    const data = {
      query: query || "SELECT * FROM `2020001.firewall.traffic` LIMIT 10",
      instance_id: instanceId,
      account_id: accountId,
      result_format: resultFormat || "valuesDictionary"
    };
    trace.start();
    dispatch({ type: "QUERY_START" });
    client
      .createQuery(data)
      .then(resp => {
        trace.incrementMetric("numberOfRecords", resp.data.num_records);
        trace.stop();
        dispatch({ type: "QUERY_SUCCESS", payload: resp.data });
      })
      .catch(err => {
        trace.stop();
        dispatch({ type: "QUERY_ERROR", payload: err });
      });
  };
}

export default submitQuery;
