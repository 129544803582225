import axios from "axios";

const BASE_URI = process.env.REACT_APP_BASE_URI;
const FIRESTORE_USER_COLLECTION =
  process.env.REACT_APP_FIRESTORE_USER_COLLECTION;

class ApiClient {
  constructor(idToken, authUser, dispatch, firestore) {
    this.idToken = idToken;
    this.authUser = authUser;
    this.dispatch = dispatch;
    this.firestore = firestore;
    this.client = axios.create({
      baseURL: BASE_URI,
      json: true,
      timeout: 30000
    });
    this.interceptor = this.client.interceptors.response.use(
      this.handleSuccess,
      this.handleError
    );
  }

  handleSuccess(response) {
    return response;
  }

  handleError = error => {
    const e = error.response;

    if (e.status !== 401) {
      return Promise.reject(error);
    }

    axios.interceptors.response.eject(this.interceptor);

    console.log("refreshing idToken...");

    return this.authUser
      .getIdToken(true)
      .then(idToken => {
        this.firestore
          .collection(FIRESTORE_USER_COLLECTION)
          .doc(this.authUser.uid)
          .set(
            {
              idToken: idToken
            },
            { merge: true }
          );
        this.dispatch({ type: "PERSIST_SIGNIN_SUCCESS" });
        const config = e.config;
        config.headers["Authorization"] = `Bearer ${idToken}`;
        return this.client(config);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };

  // Query endpoints
  createQuery(query) {
    return this.performWithIdToken("post", "/explorers/v2/query", query);
  }

  // Authz endpoints
  getAuthorizationUrl(params) {
    console.log(params);
    return this.performWithIdToken(
      "get",
      "/oauth2/v1/authorization_url",
      null,
      params
    );
  }

  // Session endpoints
  createSession(payload) {
    return this.performWithIdToken("post", "/sessions/v1/session", payload);
  }

  deleteSession(payload) {
    return this.performWithIdToken("delete", "/sessions/v1/session", payload);
  }

  updateSession(payload) {
    return this.performWithIdToken("put", "/sessions/v1/session", payload);
  }

  // Status endpoints
  getStatus() {
    return this.performWithIdToken("get", "/healthchecks/v1/status");
  }

  // Performs API request with Bearer idToken in headers
  async performWithIdToken(method, resource, data, params) {
    return this.client({
      method,
      url: resource,
      data,
      params,
      headers: {
        Authorization: `Bearer ${this.idToken}`
      }
    }).then(resp => {
      return resp ? resp : [];
    });
  }

  // Performs API request using session cookie
  async performWithSessionCookie(method, resource, data) {
    return this.client({
      method,
      url: resource,
      data
    }).then(resp => {
      return resp ? resp : [];
    });
  }
}

export default ApiClient;
