import React, { Component } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import "../../assets/css/App.css";
import firebase from "../../config/fbConfig";
import { persistSignIn } from "../../store/actions/authActions";
import { checkInstanceToAuthorize } from "../../store/actions/authzActions";
import SignInSide from "../auth";
import Base from "../layout";

ReactGA.initialize("UA-149228723-1", { debug: false });
ReactGA.pageview(window.location.pathname + window.location.search);

var parseQueryString = function(queryString) {
  var params = {},
    queries,
    temp,
    i,
    l;
  queries = queryString.split("&");
  for (i = 0, l = queries.length; i < l; i++) {
    temp = queries[i].split("=");
    params[temp[0]] = temp[1];
  }
  return params;
};

class App extends Component {
  componentDidMount() {
    this.unsubscribe = firebase.auth().onAuthStateChanged(authUser => {
      if (authUser) {
        this.props.persistSignIn(authUser);
      }
    });
    const queryString = window.location.search.substring(1);
    if (queryString.length > 0) {
      const params = parseQueryString(queryString);
      if ("params" in params) {
        try {
          const b64DecodedParams = atob(params.params);
          const decodedParams = parseQueryString(decodeURI(b64DecodedParams));
          try {
            this.props.checkInstance(decodedParams);
          } catch (err) {
            console.log(
              "Error checking if instance is already authorized,",
              err.message
            );
          }
        } catch (err) {
          console.log("Error decoding base64 params,", err.message);
          console.log("Invalid params passed in app base URL");
        }
      }
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    return (
      <div className="App">
        {this.props.firebase.auth.uid ? (
          <div>
            <Base />
          </div>
        ) : (
          <SignInSide />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    firebase: state.firebase,
    firestore: state.firestore
  };
};

const mapDispatchToProps = dispatch => {
  return {
    persistSignIn: authUser => dispatch(persistSignIn(authUser)),
    checkInstance: (instanceId, cookies) =>
      dispatch(checkInstanceToAuthorize(instanceId, cookies))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
