import { FormControl, Grid, makeStyles, Paper } from "@material-ui/core";
import { Select } from "final-form-material-ui";
import React from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { connect } from "react-redux";
import {
  selectAccount,
  selectInstance
} from "../../store/actions/authzActions";
import FormStateToRedux from "./redux/FormStateToRedux";

const useStyles = makeStyles(theme => ({
  root: {
    width: "auto",
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1)
  },
  table: {
    minWidth: 375
  },
  container: {
    minHeight: "158px",
    display: "flex",
    padding: theme.spacing(0),
    alignItems: "stretch",
    overflowX: "auto",
    overflowY: "auto"
  },
  button: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textField: {
    minWidth: 375
  },
  formControl: {
    minWidth: 375,
    padding: theme.spacing(1),
    textAlign: "left"
  },
  item: {
    padding: theme.spacing(1)
  }
}));

const foo = yoo => {
  console.log(yoo);
};

function AuthorizationForm(props) {
  const classes = useStyles();
  return (
    <div>
      <Form
        onSubmit={foo}
        // initialValues={{
        //   account_id: props.selectedAccount,
        //   instance_id: props.selectedInstance
        // }}
        render={({
          handleSubmit,
          onChange,
          reset,
          submitting,
          pristine,
          dirty,
          values,
          form
        }) => (
          <form
            className={classes.container}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <FormStateToRedux form="authorizationForm" />
            <Paper className={classes.root} elevation={2} justify="left">
              <Grid className={classes.container} container spacing={1}>
                <Grid item className={classes.item}>
                  <FormControl className={classes.formControl} margin="normal">
                    <Field
                      name="account_id"
                      component={Select}
                      label="Account"
                      autoWidth
                      native
                      initialValue={props.selectedAccount}
                    >
                      <optgroup label="Account">
                        <option disabled value=""></option>
                        <option key="sandbox1" value="999999">
                          Developer Relations
                        </option>
                        {props.supportAccounts.map((account, index) => (
                          <option
                            key={"account-" + index}
                            value={account.accountid}
                          >
                            {account.name}
                          </option>
                        ))}
                      </optgroup>
                    </Field>
                    <OnChange name="account_id">
                      {(accountId, previous) => {
                        if (dirty) {
                          props.selectAccount(accountId);
                        }
                      }}
                    </OnChange>
                  </FormControl>
                </Grid>
                <Grid item className={classes.item}>
                  <FormControl className={classes.formControl} margin="normal">
                    <Field
                      name="instance_id"
                      label="Instance"
                      component={Select}
                      autoWidth
                      native
                      initialValue={props.selectedInstance}
                    >
                      <optgroup label="Instance">
                        <option value="" disabled></option>
                        {props.instances.map((instance, index) => (
                          <option
                            key={"instance-" + index}
                            value={instance.instance_id}
                          >
                            {instance.description} ({instance.instance_id})
                          </option>
                        ))}
                      </optgroup>
                    </Field>
                    <OnChange name="instance_id">
                      {(instanceId, previous) => {
                        if (dirty) {
                          props.selectInstance(instanceId);
                        }
                      }}
                    </OnChange>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </form>
        )}
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    selectedAccount: state.firebase.profile.selectedAccount,
    selectedInstance: state.firebase.profile.selectedInstance,
    supportAccounts: state.firebase.profile.supportAccounts || [],
    instances: state.firebase.profile.instances || []
  };
};

const mapDispatchToProps = dispatch => ({
  selectAccount: accountId => dispatch(selectAccount(accountId)),
  selectInstance: instanceId => dispatch(selectInstance(instanceId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationForm);
