import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Explore from "@material-ui/icons/Explore";
import React from "react";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: "#f1c40f"
  }
}));

export default function QueryApiCard() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            Q
          </Avatar>
        }
        action={
          <Tooltip title="Open Query Explorer">
            <IconButton aria-label="settings" href="/explorers/query">
              <Explore />
            </IconButton>
          </Tooltip>
        }
        title="Query API"
        subheader=""
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Cortex Data Lake contains log data that is written by various products
          and apps, such as Palo Alto Networks next-generation firewalls. Use
          this API to create query jobs that return log data matching your query
          criteria.
        </Typography>
      </CardContent>
    </Card>
  );
}
