import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { Brightness4, Brightness7 } from "@material-ui/icons";
import Apps from "@material-ui/icons/Apps";
import Explore from "@material-ui/icons/Explore";
import Home from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import Settings from "@material-ui/icons/Settings";
import Storage from "@material-ui/icons/Storage";
import React, { useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import Cookies from "universal-cookie";
import BlackCortex from "../../assets/images/blackcortex.png";
import WhiteCortex from "../../assets/images/whitecortex.png";
import { signOut } from "../../store/actions/authActions";
import Authorization from "../authorization/authorization";
import QueryApiCard from "../dashboard/cards";
import QueryExplorer from "../explorers/query";

const cookies = new Cookies();
const defaultTheme =
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";

const drawerWidth = 240;

const themeObject = {
  palette: {
    primary: {
      light: "#54b1ee",
      main: "#1691e2",
      dark: "#137bc0"
    },
    secondary: {
      light: "#f5d658",
      main: "#f1c40f",
      dark: "#dab10d"
    },
    type: cookies.get("theme") || defaultTheme,
    jsonTheme:
      cookies.get("theme") === "dark"
        ? "monokai"
        : "rjv-default" || "rjv-default"
  },
  overrides: {
    MUIDataTableBodyCell: {
      root: {
        backgroundColor: "inherit"
      }
    },
    MUIDataTableToolbar: {
      left: {
        flex: 0
      },
      actions: {
        textAlign: "left",
        backgroundColor: "inherit"
      }
    },
    MuiTablePagination: {
      spacer: {
        flex: 0
      }
    },
    MuiFormControl: {
      root: {
        minWidth: 450
      }
    },
    MuiContainer: {}
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.white,
    borderRadius: 0,
    float: "left"
  },
  avatarColor: {
    color: "#fff",
    backgroundColor: "#f1c40f"
  },
  avatarLogo: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.white,
    borderRadius: 0,
    marginLeft: "36%",
    marginTop: "4%",
    height: "auto",
    width: theme.spacing(6)
  },
  avatarLogoMain: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    height: "auto",
    width: theme.spacing(6),
    marginLeft: 70
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxWidth: "sm",
    minWidth: "100%"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(9)
    }
  },
  drawerTitle: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  drawerToolbar: {
    display: "initial"
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 999
  },
  fixedHeight: {
    height: 240
  },
  listItem: {
    minWidth: 56
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  menuTitle: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
    paddingLeft: 25
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  toolbar: theme.mixins.toolbar,
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  toolbarLogo: {
    display: "flex",
    alignItems: "center"
  },
  title: {
    flexGrow: 1
  }
}));

const useDarkMode = () => {
  const [theme, setTheme] = useState(themeObject);

  const {
    palette: { type, jsonTheme }
  } = theme;
  const toggleDarkMode = () => {
    const updatedTheme = {
      ...theme,
      palette: {
        ...theme.palette,
        type: type === "light" ? "dark" : "light",
        jsonTheme: jsonTheme === "monokai" ? "rjv-default" : "monokai"
      }
    };
    setTheme(updatedTheme);
  };
  cookies.set("theme", type, { path: "/", secure: true, httpOnly: true });
  return [createMuiTheme(theme), toggleDarkMode];
};

const Base = props => {
  const { container } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [theme, toggleDarkMode] = useDarkMode();

  function GetDisplayName() {
    if (props.auth.displayName) {
      return "Logout " + props.auth.displayName;
    }
    return "";
  }

  function GetAvatar() {
    if (props.auth.photoURL) {
      return <Avatar src={props.auth.photoURL} />;
    }
    if (props.auth.displayName) {
      return (
        <Avatar className={classes.avatarColor}>
          {props.auth.displayName[0]}
        </Avatar>
      );
    }
    return <Avatar className={classes.avatarColor}></Avatar>;
  }

  const drawer = (
    <>
      <Toolbar className={classes.drawerToolbar}>
        <Avatar
          variant="square"
          src={theme.palette.type === "dark" ? WhiteCortex : BlackCortex}
          className={classes.avatarLogo}
        ></Avatar>
      </Toolbar>
      <Divider />
      <List>
        <ListItem button component="a" href="/">
          <ListItemIcon className={classes.listItem}>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem
          button
          component="a"
          href="https://apps.paloaltonetworks.com/apps"
        >
          <ListItemIcon className={classes.listItem}>
            <Apps />
          </ListItemIcon>
          <ListItemText primary="Cortex hub" />
        </ListItem>
        <ListItem>
          <ListItemIcon className={classes.listItem}>
            <Storage />
          </ListItemIcon>
          <ListItemText primary="Cortex Data Lake" />
        </ListItem>
        <List component="div" disablePadding>
          <ListItem
            className={classes.nested}
            button
            component="a"
            href="/explorers/query"
          >
            <ListItemIcon className={classes.listItem}>
              <Explore />
            </ListItemIcon>
            <ListItemText primary="Query Explorer" />
          </ListItem>
        </List>
      </List>
    </>
  );

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar} variant="dense">
              <Avatar
                variant="square"
                src={WhiteCortex}
                className={classes.avatarLogoMain}
              ></Avatar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              />
              {props.profile.instanceToAuthorize ? (
                <Tooltip title="New instance detected!">
                  <IconButton color="inherit" href="/authorization">
                    <Badge badgeContent={1} color="secondary">
                      <NotificationImportantIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
              <Tooltip title="Toggle light/dark theme">
                <IconButton color="inherit" onClick={toggleDarkMode}>
                  {theme.palette.type === "dark" ? (
                    <Brightness7 />
                  ) : (
                    <Brightness4 />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Authorization">
                <IconButton color="inherit" href="/authorization">
                  <Settings />
                </IconButton>
              </Tooltip>
              <Tooltip title={GetDisplayName()}>
                <IconButton color="inherit" onClick={props.signOut}>
                  <Badge color="secondary">
                    <GetAvatar />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="drawer menu">
            <Hidden mdUp implementation="js">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="js">
              <Drawer
                classes={{
                  paper: classes.drawerPaper
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
            <div id="back-to-top-anchor" />
            <div className={classes.appBarSpacer} />
            <Container className={classes.container}>
              <BrowserRouter>
                <Route exact path="/" component={QueryApiCard} />
                <Route
                  path="/explorers/query"
                  component={() => <QueryExplorer theme={theme} />}
                />
                <Route path="/authorization" component={Authorization} />
              </BrowserRouter>
            </Container>
          </main>
        </div>
      </MuiThemeProvider>
    </>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Base);
