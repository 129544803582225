import ApiClient from "../../utils/api";

const FIRESTORE_USER_COLLECTION =
  process.env.REACT_APP_FIRESTORE_USER_COLLECTION;
const FIRESTORE_TENANT_COLLECTION =
  process.env.REACT_APP_FIRESTORE_TENANT_COLLECTION;

export function selectAccount(accountId) {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({ type: "SELECT_ACCOUNT_START" });
    const firestore = getFirestore();
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    const uid = user.uid;
    dispatch({ type: "GET_INSTANCES_START" });
    const instances = [];
    firestore
      .collection(FIRESTORE_TENANT_COLLECTION)
      .where("account_id", "==", accountId)
      .get()
      .then(snapshot => {
        dispatch({ type: "GET_INSTANCES_SUCCESS" });
        snapshot.forEach(doc => {
          instances.push(doc.data());
        });
        const selectedAccount = {
          selectedAccount: accountId,
          instances: instances,
          selectedInstance: null
        };
        firestore
          .collection(FIRESTORE_USER_COLLECTION)
          .doc(uid)
          .set(selectedAccount, { merge: true })
          .then(() => {
            dispatch({ type: "SELECT_ACCOUNT_SUCCESS" });
          })
          .catch(err => {
            console.log(err);
            dispatch({ type: "SELECT_ACCOUNT_ERROR", err });
          });
      })
      .catch(err => {
        dispatch({ type: "GET_INSTANCES_ERROR", err });
      });
  };
}

export function selectInstance(instanceId) {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({ type: "SELECT_INSTANCE_START" });
    const firestore = getFirestore();
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    const uid = user.uid;
    const selectedInstance = {
      selectedInstance: instanceId
    };
    firestore
      .collection(FIRESTORE_USER_COLLECTION)
      .doc(uid)
      .set(selectedInstance, { merge: true })
      .then(() => {
        dispatch({ type: "SELECT_INSTANCE_SUCCESS" });
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: "SELECT_INSTANCE_ERROR", err });
      });
  };
}

export function checkInstanceToAuthorize(params) {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({ type: "CHECK_INSTANCE_TO_AUTHZ_START" });
    const firestore = getFirestore();
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    const uid = user.uid;
    firestore
      .collection(FIRESTORE_TENANT_COLLECTION)
      .where("instance_id", "==", params.instance_id)
      .get()
      .then(snapshot => {
        if (snapshot.docs.length > 0) {
          console.log("instance already authorized");
        } else {
          console.log("detected new instance to authorize");
          firestore
            .collection(FIRESTORE_USER_COLLECTION)
            .doc(uid)
            .set({ instanceToAuthorize: params }, { merge: true })
            .then(() => {
              dispatch({ type: "CHECK_INSTANCE_TO_AUTHZ_SUCCESS" });
            })
            .catch(err => {
              console.log(err);
              dispatch({ type: "CHECK_INSTANCE_TO_AUTHZ_ERROR", err });
            });
        }
        dispatch({ type: "CHECK_INSTANCE_TO_AUTHZ_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "CHECK_INSTANCE_TO_AUTHZ_ERROR", err });
      });
  };
}

export function getAuthorizationUrl(instanceToAuthorize) {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({ type: "GET_AUTHZ_URL_START" });
    const params = instanceToAuthorize.instance;
    const firebase = getFirebase();
    const firestore = getFirestore();
    const idToken = getState().firebase.profile.idToken;
    const client = new ApiClient(
      idToken,
      firebase.auth().currentUser,
      dispatch,
      firestore
    );
    client
      .getAuthorizationUrl(params)
      .then(resp => {
        console.log(resp.data);
        dispatch({ type: "GET_AUTHZ_URL_SUCCESS", payload: resp.data });
        window.location.replace(resp.data.result.authorization_url);
      })
      .catch(err => {
        dispatch({ type: "GET_AUTHZ_URL_ERROR", payload: err });
      });
  };
}
