const authzReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case "SELECT_ACCOUNT_ERROR":
      console.log("select account failed", action);
      return {
        ...state
      };
    case "SELECT_ACCOUNT_START":
      console.log("select account start");
      return {
        ...state
      };
    case "SELECT_ACCOUNT_SUCCESS":
      console.log("select account success");
      return {
        ...state
      };
    case "SELECT_INSTANCE_ERROR":
      console.log("select instance failed", action);
      return {
        ...state
      };
    case "SELECT_INSTANCE_START":
      console.log("select instance start");
      return {
        ...state
      };
    case "SELECT_INSTANCE_SUCCESS":
      console.log("select instance success");
      return {
        ...state
      };
    case "GET_INSTANCES_START":
      console.log("get instances start");
      return {
        ...state
      };
    case "GET_INSTANCES_ERROR":
      console.log("get instances error");
      return {
        ...state
      };
    case "GET_INSTANCES_SUCCESS":
      console.log("get instances success");
      return {
        ...state
      };
    case "CHECK_INSTANCE_TO_AUTHZ_START":
      console.log("check instance to authorize start");
      return {
        ...state
      };
    case "CHECK_INSTANCE_TO_AUTHZ_ERROR":
      console.log("check instance to authorize error");
      return {
        ...state
      };
    case "CHECK_INSTANCE_TO_AUTHZ_SUCCESS":
      console.log("check instance to authorize success");
      return {
        ...state
      };
    default:
      return state;
  }
};

export default authzReducer;
