import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import ReactJson from "react-json-view";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import QueryForm from "../../forms/QueryForm";

const cookies = new Cookies();

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    overflowX: "auto",
    overflowY: "auto"
  },
  table: {
    minWidth: 650
  },
  container: {
    minHeight: "100px",
    padding: theme.spacing(1)
  },
  button: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(2)
  },
  textField: {
    width: 580
  },
  formControl: {
    width: 580
  }
}));

function getSpinner() {
  return <CircularProgress color="secondary" />;
}

const options = {
  filterType: "multiselect",
  responsive: "scroll",
  download: true,
  print: false,
  selectableRows: "none",
  rowsPerPage: 25,
  rowsPerPageOptions: [10, 25, 50, 100],
  downloadOptions: {
    filename: `apiexplorer_export.csv`,
    separator: ","
  },
  textLabels: {
    body: {
      noMatch: getSpinner(),
      toolTip: "Sort"
    },
    pagination: {
      next: "Next Page",
      previous: "Previous Page",
      rowsPerPage: "Records per page:",
      displayRows: "of"
    }
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`
  };
}

function QueryExplorer(props) {
  const [activeTabIndex, setActiveTabIndex] = useState(
    parseInt(cookies.get("activeQueryTab")) || 0
  );
  const [resultsShown, setResultsShown] = useState(false);
  const classes = useStyles();
  const setTabIndex = index => {
    setActiveTabIndex(index);
    cookies.set("activeQueryTab", index, { path: "/" });
  };
  if (props.explorer.queryComplete && !resultsShown) {
    setResultsShown(true);
    console.table({
      num_records: props.explorer.results.stats.num_records,
      transactions: props.explorer.results.stats.transactions
    });
  }
  return (
    <div>
      <QueryForm />
      <Paper elevation={2} className={classes.root}>
        <MuiThemeProvider theme={props.theme}>
          {props.form.submitSucceeded || props.explorer.queryComplete ? (
            <div>
              <Tabs
                value={activeTabIndex}
                onChange={(event, value) => setTabIndex(value)}
              >
                <Tab label="TABULAR" {...a11yProps(0)} />
                <Tab
                  label="RAW"
                  {...a11yProps(1)}
                  disabled={props.explorer.queryComplete ? false : true}
                />
              </Tabs>
              <TabPanel value={activeTabIndex} index={0}>
                <MUIDataTable
                  data={props.explorer.results.records}
                  columns={props.explorer.results.headers}
                  options={options}
                  style={{ overflowX: "auto", overflowY: "auto" }}
                />
              </TabPanel>
              <TabPanel
                value={activeTabIndex}
                index={1}
                style={{
                  textAlign: "left",
                  margin: "20px 20px 20px 20px"
                }}
              >
                {props.explorer.results.raw_results.length > 0 ? (
                  <ReactJson
                    src={props.explorer.results.raw_results}
                    displayDataTypes={false}
                    indentWidth={2}
                    theme={props.theme.palette.jsonTheme}
                  ></ReactJson>
                ) : (
                  <div style={{ textAlign: "center" }}>{getSpinner()}</div>
                )}
              </TabPanel>
            </div>
          ) : props.explorer.queryFailed ? (
            <p>{props.explorer.queryFailed}</p>
          ) : (
            <div />
          )}
        </MuiThemeProvider>
      </Paper>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    form: state.form.queryForm || {},
    explorer: state.explorer
  };
};

export default connect(mapStateToProps, null)(QueryExplorer);
