const initState = {
  queryFailed: null,
  queryComplete: false,
  queryInProgress: false,
  results: {
    records: [],
    headers: [],
    raw_results: [],
    stats: []
  }
};

const explorerReducer = (state = initState, action) => {
  switch (action.type) {
    case "QUERY_ERROR":
      console.log("query failed", action);
      return {
        ...state,
        queryFailed: action.payload.message,
        queryComplete: true,
        queryInProgress: false
      };
    case "QUERY_START":
      console.log("query start");
      return {
        ...state,
        queryFailed: null,
        queryComplete: false,
        queryInProgress: true,
        results: {
          records: [],
          headers: [],
          raw_results: [],
          stats: []
        }
      };
    case "QUERY_SUCCESS":
      console.log("query success");
      return {
        ...state,
        results: action.payload,
        queryFailed: null,
        queryComplete: true,
        queryInProgress: false
      };
    default:
      return state;
  }
};

export default explorerReducer;
