const FIRESTORE_USER_COLLECTION =
  process.env.REACT_APP_FIRESTORE_USER_COLLECTION;

export function signIn(authUser) {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    dispatch({ type: "SIGNIN_START" });
    const firestore = getFirestore();
    const firebase = getFirebase();
    const provider = authUser.additionalUserInfo.providerId;
    const profile = authUser.additionalUserInfo.profile;
    authUser.user
      .getIdToken()
      .then(idToken => {
        const lastLogin = new Date().getTime();
        const email = profile.email;
        const uid = authUser.user.uid;
        var displayName = null;
        var userProfile = null;
        if (provider === "saml.apiexplorer") {
          const supportAccounts = JSON.parse(profile.supportaccountids);
          displayName = `${profile.firstname} ${profile.lastname}`;
          userProfile = {
            displayName: displayName,
            lastLogin: lastLogin,
            email: email,
            supportAccounts: supportAccounts
          };
        } else {
          displayName = profile.name;
          userProfile = {
            displayName: displayName,
            lastLogin: lastLogin,
            email: email
          };
        }
        firebase
          .auth()
          .currentUser.updateProfile(userProfile)
          .then(console.log("user profile updated successfully"))
          .catch("error updating user profile");
        firestore
          .collection(FIRESTORE_USER_COLLECTION)
          .doc(uid)
          .set(userProfile, { merge: true });
      })
      .then(() => {
        dispatch({ type: "SIGNIN_SUCCESS" });
      })
      .catch(err => {
        console.log(err);
        dispatch({ type: "SIGNIN_ERROR", err });
      });
  };
}

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        firebase.logout();
      })
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
        window.location.replace("/");
      });
  };
};

export function persistSignIn(authUser) {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: "PERSIST_SIGNIN_START" });
    const firestore = getFirestore();
    authUser
      .getIdTokenResult()
      .then(result => {
        var currentTime = Date.now();
        var expirationTime = Date.parse(result.expirationTime);
        if (expirationTime - currentTime < 0) {
          console.log("(refreshing idToken...)");
          const lastLogin = new Date().getTime();
          authUser.getIdToken(true).then(idToken => {
            firestore
              .collection(FIRESTORE_USER_COLLECTION)
              .doc(authUser.uid)
              .set({ lastLogin: lastLogin }, { merge: true });
          });
        }
      })
      .then(() => {
        dispatch({ type: "PERSIST_SIGNIN_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "PERSIST_SIGNIN_ERROR", err });
      });
  };
}
