import { FormControl, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Select } from "final-form-material-ui";
import React from "react";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { getAuthorizationUrl } from "../../store/actions/authzActions";
import FormStateToRedux from "./redux/FormStateToRedux";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    textAlign: "left"
  },
  table: {
    minWidth: 350
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  container: {
    display: "flex",
    padding: theme.spacing(0),
    alignItems: "stretch",
    overflowX: "auto",
    overflowY: "auto"
  },
  button: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  textField: {
    minWidth: 250
  },
  formControl: {
    minWidth: 250,
    padding: theme.spacing(1),
    textAlign: "left"
  },
  item: {
    padding: theme.spacing(0)
  }
}));

function InstanceAuthorizationForm(props) {
  const classes = useStyles();
  return (
    <div>
      <Form
        onSubmit={props.getAuthorizationUrl}
        initialValues={{ instance: props.instance }}
        render={({
          handleSubmit,
          onChange,
          reset,
          submitting,
          pristine,
          dirty,
          values,
          form
        }) => (
          <form
            className={classes.container}
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <FormStateToRedux form="authorizationForm" />
            <div className={classes.root}>
              <Grid
                container
                spacing={3}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={12} className={classes.item}>
                  <FormControl>
                    <Field
                      name="account_id"
                      component={Select}
                      label="Account *"
                      autoWidth
                      native
                      initialValue=""
                      required
                    >
                      <optgroup label="Account">
                        <option disabled value=""></option>
                        {props.supportAccounts.map((account, index) => (
                          <option
                            key={"account-" + index}
                            value={account.accountid}
                          >
                            {account.name}
                          </option>
                        ))}
                      </optgroup>
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item md={12} className={classes.item}>
                  <FormControl>
                    <Field
                      name="scopes"
                      component={Select}
                      label="Scopes *"
                      autoWidth
                      native
                      multiple
                      required
                      initialValue=""
                    >
                      <option disabled value=""></option>
                      <option value="logging-service:read">
                        logging-service:read
                      </option>
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item md={6} className={classes.item}>
                  <Button variant="contained" color="secondary" type="submit">
                    Authorize
                  </Button>
                </Grid>
              </Grid>
            </div>
            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </form>
        )}
      />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    selectedAccount: state.firebase.profile.selectedAccount,
    supportAccounts: state.firebase.profile.supportAccounts || []
  };
};

const mapDispatchToProps = dispatch => ({
  getAuthorizationUrl: params => dispatch(getAuthorizationUrl(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstanceAuthorizationForm);
