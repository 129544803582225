import React from "react";
import { FormSpy } from "react-final-form";
import { connect } from "react-redux";
import { updateFormState } from "../../../store/actions/formActions";

const FormStateToRedux = ({ form, updateFormState }) => (
  <FormSpy
    subscription={{ submitting: true, submitSucceeded: true }}
    onChange={state => updateFormState(form, state)}
  />
);

export default connect(
  undefined,
  { updateFormState }
)(FormStateToRedux);
