import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import pink from "@material-ui/core/colors/pink";
import Divider from "@material-ui/core/Divider";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Delete from "@material-ui/icons/Delete";
import LockOpen from "@material-ui/icons/LockOpen";
import React from "react";
import { connect } from "react-redux";
import { getAuthorizationUrl } from "../../store/actions/authzActions";
import AuthorizationForm from "../forms/AuthorizationForm";
import InstanceAuthorizationForm from "../forms/InstanceAuthorizationForm";

const useStyles = makeStyles(theme => ({
  root: {
    width: "auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
    overflowX: "auto",
    overflowY: "auto"
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    width: 365,
    height: "auto",
    transform: "translate(-50%, -50%)"
  },
  table: {
    minWidth: 375,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6)
  },
  formControl: {
    minWidth: 375,
    paddingBottom: 25,
    paddingLeft: 15,
    paddingTop: 15
  },
  selectEmpty: {},
  title: {
    flex: "1 1 100%"
  }
}));

const DeleteButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
    "&:hover": {
      backgroundColor: pink[700]
    }
  }
}))(Button);

function Authorization(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AuthorizationForm />
      <Paper elevation={2} className={classes.root}>
        <Table className={classes.table} aria-label="simple table">
          {props.instances.length > 0 || props.newInstance ? (
            <>
              <Modal
                aria-labelledby="authorization-modal"
                open={open}
                onClose={handleClose}
              >
                <div className={classes.paper}>
                  <h2>Authorization</h2>
                  <Divider />
                  <InstanceAuthorizationForm instance={props.newInstance} />
                </div>
              </Modal>
              <TableHead>
                <TableRow>
                  <TableCell>ACCOUNT</TableCell>
                  <TableCell align="right">DESCRIPTION</TableCell>
                  <TableCell align="right">INSTANCE_ID</TableCell>
                  <TableCell align="right">SCOPE</TableCell>
                  <TableCell align="right">REGION</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.newInstance ? (
                  <TableRow selected>
                    <TableCell>TBD</TableCell>
                    <TableCell align="right">
                      {props.newInstance.description}
                    </TableCell>
                    <TableCell align="right">
                      <Chip
                        color="default"
                        label={props.newInstance.instance_id}
                      ></Chip>
                    </TableCell>
                    <TableCell align="right">TBD</TableCell>
                    <TableCell align="right">
                      <Chip
                        color="primary"
                        label={props.newInstance.region}
                      ></Chip>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<LockOpen />}
                        size="small"
                        // onClick={() =>
                        //   props.getAuthorizationUrl(props.newInstance)
                        // }
                        onClick={handleOpen}
                      >
                        Authorize
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
                {props.instances.map((instance, index) => (
                  <TableRow key={"tenant-" + index}>
                    <TableCell component="th" scope="row">
                      {instance.account_name}
                    </TableCell>
                    <TableCell align="right">{instance.description}</TableCell>
                    <TableCell align="right">
                      <Chip color="default" label={instance.instance_id}></Chip>
                    </TableCell>
                    <TableCell align="right">
                      {instance.scope.split(" ").map((scope, index) => (
                        <Chip
                          key={"chip-" + index}
                          color="secondary"
                          label={scope}
                        ></Chip>
                      ))}
                    </TableCell>
                    <TableCell align="right">
                      <Chip color="primary" label={instance.region}></Chip>
                    </TableCell>
                    <TableCell>
                      <DeleteButton
                        variant="contained"
                        startIcon={<Delete />}
                        size="small"
                        disabled={
                          instance.account_name === "Developer Relations"
                        }
                      >
                        Delete
                      </DeleteButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell>No available instances to display.</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Paper>
    </>
  );
}

const mapStateToProps = state => {
  return {
    instances: state.firebase.profile.instances || [],
    newInstance: state.firebase.profile.instanceToAuthorize
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAuthorizationUrl: params => dispatch(getAuthorizationUrl(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);
